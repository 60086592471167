import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const MiniContent = ({ title, content, itemsPerRow, link })  =>  (
  <div className={`p-2 md:w-1/${itemsPerRow} flex-auto`}>
    
    <div className={`flex flex-col text-qblue text-center rounded h-full shadow`}>
      <div className='bg-qorange h-1 rounded-t bg-gradient-to-r from-qorange to-qorange2 '  />
      <div className='flex-grow px-4 pb-8 '>
        <h3 className='mb-4' dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      { link ? <Link to={link.url}>
        <div className='flex mt-8 -mt-1 pb-8 px-4'>
          <div className='flex-1'/>
          <button className='transition duration-700 ease-in-out border px-4 py-2 rounded-full border-qblue font-bold hover:bg-qblue hover:text-white'>Mehr erfahren</button>
        </div>
      </Link> 
      : null } 

    </div>
  </div>

)

MiniContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  link: PropTypes.object
};

export default MiniContent
