import React from "react";
import { graphql } from "gatsby"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MiniContent from '../../components/mini-content.js'
import PropTypes from "prop-types";

const AboutPage = ({ data: { allMdx: { edges: angebote } } }) => {

  return (
    <Layout>
      <SEO
        keywords={[`erziehungsberatung`, `familienhilfe`, `beratungsangebote`, `pflegeeltern`, `bindung`, `clearing`, `nrw`, `krefeld`, `mülheim`, `ratingen`]}
        title="Angebote"
      />

      <div className='flex flex-wrap'>
        { angebote.map(x => (
          <MiniContent
            key={x.node.frontmatter.title}
            itemsPerRow={2}
            title={x.node.frontmatter.title}
            content={x.node.frontmatter.teaser}
            link={{ url: x.node.frontmatter.slug ? x.node.frontmatter.slug : null }}
          />))
        }
        { angebote.length % 2 === 1 ? <div className={`p-2 md:w-1/2 flex-auto`} /> : null }
      </div>
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object,
  angebote: PropTypes.arrayOf(PropTypes.object)
};

export default AboutPage;
export const pageQuery = graphql`
{
  allMdx(
    filter: { fields: { collection: { eq: "angebote" } } }
    sort:{fields:frontmatter___order} ) {
    edges {
      node {
        excerpt
        frontmatter {
          title
          slug
          teaser
        }
      }
    }
  }
}`
